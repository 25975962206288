import React from "react"

export default () => (
<React.Fragment>
    <svg id="rect-one" width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Desktop-HD" transform="translate(-517.000000, -788.000000)" stroke="#FFFFFF">
                    <rect id="Rectangle-Copy" transform="translate(523.123724, 794.123724) rotate(-345.000000) translate(-523.123724, -794.123724) " x="518.623724" y="789.623724" width="9" height="9"></rect>
                </g>
            </g>
        </svg>
        <svg id="rect-two" width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Desktop-HD" transform="translate(-929.000000, -305.000000)" stroke="#FFFFFF">
                    <rect id="Rectangle" transform="translate(935.123724, 311.123724) rotate(-15.000000) translate(-935.123724, -311.123724) " x="930.623724" y="306.623724" width="9" height="9"></rect>
                </g>
            </g>
        </svg>
        <svg id="plus" width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                <g id="Desktop-HD" transform="translate(-669.000000, -244.000000)" stroke="#FFFFFF">
                    <g id="Group" transform="translate(669.000000, 245.000000)">
                        <path d="M7.5,14 L7.5,0" id="Line"></path>
                        <path d="M7.5,14 L7.5,0" id="Line" transform="translate(7.500000, 7.000000) rotate(90.000000) translate(-7.500000, -7.000000) "></path>
                    </g>
                </g>
            </g>
        </svg>
        <svg id="oval-one" width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Desktop-HD" transform="translate(-979.000000, -323.000000)" stroke="#FFFFFF">
                    <circle id="Oval" cx="985" cy="329" r="5"></circle>
                </g>
            </g>
        </svg>
        <svg id="oval-two" width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Desktop-HD" transform="translate(-979.000000, -323.000000)" stroke="#FFFFFF">
                    <circle id="Oval" cx="985" cy="329" r="5"></circle>
                </g>
            </g>
        </svg>
        <svg id="background" width="63.6rem" height="47.3rem" viewBox="0 0 636 473" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Desktop-HD" transform="translate(-420.000000, -295.000000)" fill="#F1E9D8">
                    <path d="M520.911281,561.205269 C453.895551,625.672763 423.510507,682.438008 466.704923,738.681859 C509.89934,794.92571 572.884678,845.433894 689.782987,781.094684 C767.715193,738.201877 822.047656,653.234075 852.780376,526.191278 C941.494065,498.011013 992.776759,465.203936 1006.62846,427.770047 C1027.40601,371.619215 1027.80324,299.678388 960.814814,264.05798 C893.826382,228.437573 822.799285,287.028258 799.882838,310.733822 C784.605207,326.53753 790.921022,356.274881 818.830282,399.945874 C664.894768,464.473808 565.588435,518.22694 520.911281,561.205269 Z" id="Path" transform="translate(732.791134, 532.022038) rotate(74.000000) translate(-732.791134, -532.022038) "></path>
                </g>
            </g>
        </svg>
</React.Fragment>
);