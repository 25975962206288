import React from "react"
import Img from "gatsby-image"
import Icons from "../components/icons"
import SocialIcons from "../components/social"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "./index.scss"

export default () => {
  const data = useStaticQuery(graphql`
    query {
        file(relativePath: { eq: "images/profile.jpg" }) {
            childImageSharp {
                fixed(width: 160, height: 160, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
            }
        }
    }
  `);

  return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Hello | Zan Marolt</title>
        </Helmet>
        <div id="wrapper">
            <div className="greeting">
                <Icons></Icons>
                <div className="greeting__info-box">
                    <div className="greeting__info-box__profile-box">
                        <Img
                            fixed={data.file.childImageSharp.fixed}
                            alt="Profile Zan Marolt"
                        />
                    </div>
                    <div className="greeting__info-box__emoji">
                        <span role="img" aria-label="wave">👋</span>
                    </div>
                    <p className="greeting__info-box--subtitle">Hello, my name is</p>
                    <h1 className="greeting__info-box--title">Zan Marolt</h1>
                    <p className="greeting__info-box--description">
                        I am building fast, usable and nice looking web applications and websites. I also like food and travel.
                    </p>
                    <div className="greeting__info-box__social">
                        <SocialIcons></SocialIcons>
                    </div>
                </div>
        </div>
        
    </div>
      </React.Fragment>
  )
}