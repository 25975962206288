import React from "react"

export default () => {
    const links = [
        {
            href: 'https://www.linkedin.com/in/zan-marolt-98b114ab/',
            component: (<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g className="link-icon" transform="translate(-510.000000, -711.000000)" fill="#D0D0D0" fillRule="nonzero">
                    <g id="Group-2" transform="translate(510.000000, 711.000000)">
                        <g id="linkedin-(1)">
                            <path d="M10,0 C4.47799684,0 0,4.47799684 0,10 C0,15.5220032 4.47799684,20 10,20 C15.5220032,20 20,15.5220032 20,10 C20,4.47799684 15.5220032,0 10,0 Z M7.09411621,15.1171875 L4.6586609,15.1171875 L4.6586609,7.79006957 L7.09411621,7.79006957 L7.09411621,15.1171875 Z M5.87646484,6.78955078 L5.8605957,6.78955078 C5.04333496,6.78955078 4.51477051,6.22695922 4.51477051,5.52383422 C4.51477051,4.80484008 5.0595093,4.2578125 5.89263914,4.2578125 C6.72576906,4.2578125 7.23846437,4.80484008 7.25433352,5.52383422 C7.25433352,6.22695922 6.72576906,6.78955078 5.87646484,6.78955078 Z M15.8758545,15.1171875 L13.4407043,15.1171875 L13.4407043,11.1973572 C13.4407043,10.2122498 13.0880738,9.54040527 12.2068787,9.54040527 C11.5341187,9.54040527 11.1334229,9.99359133 10.9573364,10.4310608 C10.8929443,10.587616 10.8772278,10.806427 10.8772278,11.0253906 L10.8772278,15.1171875 L8.44192504,15.1171875 C8.44192504,15.1171875 8.47381594,8.47747805 8.44192504,7.79006957 L10.8772278,7.79006957 L10.8772278,8.82751465 C11.2008667,8.32824707 11.7799377,7.61810305 13.072052,7.61810305 C14.6743774,7.61810305 15.8758545,8.66531371 15.8758545,10.9158325 L15.8758545,15.1171875 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>)
        },
        {
            href: 'mailto:marolt@zan.sh',
            component: (<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g className="link-icon" transform="translate(-545.000000, -711.000000)" fill="#D0D0D0" fillRule="nonzero">
                    <g id="Group-2" transform="translate(510.000000, 711.000000)">
                        <g id="email-(1)" transform="translate(35.000000, 0.000000)">
                            <path d="M9.99973333,0 C4.47713333,0 6.66666667e-05,4.4772 6.66666667e-05,9.9998 C6.66666667e-05,15.5222667 4.47713333,19.9998 9.99973333,19.9998 C15.5223333,19.9998 19.9997333,15.5222667 19.9997333,9.9998 C19.9997333,4.4772 15.5223333,0 9.99973333,0 Z M9.99993333,3.5124 L15.9174667,7.2024 L4.0824,7.2024 L9.99993333,3.5124 Z M15.9912,13.0948667 L15.9906,13.0948667 C15.9906,13.6867333 15.5109333,14.1663333 14.9191333,14.1663333 L5.08073333,14.1663333 C4.48886667,14.1663333 4.00926667,13.6866667 4.00926667,13.0948667 L4.00926667,7.4372 C4.00926667,7.3746 4.01566667,7.31373333 4.02606667,7.25393333 L9.67993333,10.7794667 C9.68686667,10.7838 9.69426667,10.7867333 9.7014,10.7907333 C9.70886667,10.7948667 9.71646667,10.7988667 9.72406667,10.8026667 C9.764,10.8232667 9.80513333,10.8398667 9.8472,10.8507333 C9.85153333,10.8519333 9.85586667,10.8524667 9.8602,10.8534667 C9.90633333,10.8643333 9.95306667,10.8711333 9.99973333,10.8711333 L10.0000667,10.8711333 C10.0004,10.8711333 10.0007333,10.8711333 10.0007333,10.8711333 C10.0474,10.8711333 10.0941333,10.8645333 10.1402667,10.8534667 C10.1446,10.8524 10.1489333,10.8519333 10.1532667,10.8507333 C10.1952667,10.8398667 10.2362667,10.8232667 10.2764,10.8026667 C10.284,10.7988667 10.2916,10.7948667 10.2990667,10.7907333 C10.3061333,10.7867333 10.3136,10.7838 10.3205333,10.7794667 L15.9744,7.25393333 C15.9848,7.31373333 15.9912,7.37446667 15.9912,7.4372 L15.9912,13.0948667 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>)
        },
        {
            href: 'https://github.com/ZanMarolt',
            component: (<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g className="link-icon" transform="translate(-580.000000, -711.000000)" fill="#D0D0D0" fillRule="nonzero">
                    <g id="Group-2" transform="translate(510.000000, 711.000000)">
                        <g id="github" transform="translate(70.000000, 0.000000)">
                            <path d="M9.99875,0 C4.4775,0 0,4.59056119 0,10.2534448 C0,14.7827304 2.865,18.6252191 6.84,19.9822176 C7.34,20.0766841 7.5225,19.7600936 7.5225,19.4881833 C7.5225,19.2443576 7.51375,18.5996876 7.50875,17.7443828 C4.7275,18.3635213 4.14,16.3695123 4.14,16.3695123 C3.68625,15.1848513 3.03,14.8695375 3.03,14.8695375 C2.12125,14.2338035 3.0975,14.2465692 3.0975,14.2465692 C4.10125,14.319334 4.62875,15.3035727 4.62875,15.3035727 C5.52125,16.8699294 6.97,16.4180221 7.54,16.1550478 C7.63,15.4925057 7.88875,15.0405984 8.175,14.784007 C5.955,14.5248624 3.62,13.6453027 3.62,9.71728359 C3.62,8.59772793 4.01,7.68242415 4.65,6.9649894 C4.54625,6.70584481 4.20375,5.66288361 4.7475,4.252269 C4.7475,4.252269 5.5875,3.97652895 7.4975,5.30288965 C8.295,5.07565943 9.15,4.96204432 10.00125,4.95821459 C10.85,4.96332089 11.70625,5.07565943 12.505,5.30416623 C14.41375,3.97780553 15.2525,4.25354558 15.2525,4.25354558 C15.7975,5.66543676 15.455,6.70712139 15.3525,6.96626597 C15.99375,7.68370073 16.38,8.5990045 16.38,9.71856017 C16.38,13.6567919 14.0425,14.5235858 11.815,14.7776241 C12.17375,15.0942146 12.49375,15.719736 12.49375,16.6758901 C12.49375,18.0469309 12.48125,19.1524443 12.48125,19.4881833 C12.48125,19.7626468 12.66125,20.0817904 13.16875,19.980941 C17.1375,18.6226659 20,14.7814539 20,10.2534448 C20,4.59056119 15.5225,0 9.99875,0 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>)
        }
    ];
    return (
        <React.Fragment>
            {links.map((link) => (<a href={link.href} key={link.href}>{link.component}</a>))}
        </React.Fragment>
    )
};